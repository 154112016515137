/* global $ */
/* global gtag */
/* global yaCounter38825985 */

document.addEventListener('DOMContentLoaded', () => {
  const $forms = $('form.trackable-form');

  $forms.on('ajax:success', ({ target }) => {
    yaTrack(target);
    gaTrack(target);
  });
});

function gaTrack (form) {
  if (!window.gtag) return console.warn('window.gtag not found');

  const {
    gaLabel,
    gaAction,
    gaCategory,
  } = form.dataset;

  gtag('event', gaAction, {
    event_category: gaCategory,
    event_label: gaLabel,
  });
}

function yaTrack (form) {
  if (!window.yaCounter38825985) return console.warn('yaCounter38825985 not found');

  const { yaLabel } = form.dataset;

  yaCounter38825985.reachGoal(yaLabel);
}
